@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/CircularStd-Book.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/CircularStd-BookItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/CircularStd-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/CircularStd-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/CircularStd-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/CircularStd-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/CircularStd-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/CircularStd-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Arial';
  src: url('../assets/fonts/arial.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Verdana';
  src: url('../assets/fonts/verdana.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'TrebuchetMS';
  src: url('../assets/fonts/trebuc.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'TimesNewRomanPSMT';
  src: url('../assets/fonts/timesNewRomanPSMT.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Didot';
  src: url('../assets/fonts/didot.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Georgia';
  src: url('../assets/fonts/georgia.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'AmericanTypewriter';
  src: url('../assets/fonts/americanTypewriter.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Bradley Hand';
  src: url('../assets/fonts/bradley.ttf');
  font-weight: 400;
}

.container {
  height: 300px;
  display: flex;
  flex-direction: column;
}

.container > div:first-of-type {
  margin-bottom: 10px;
}

.container > div:last-of-type {
  flex-grow: 1;
  overflow: auto;
}

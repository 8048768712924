.tplEditor {
  font-family: Arial sans-serif;
  font-size: 18px;
  padding: 0 0.5em;
}

.tplEditor h1 {
  font-size: 2.5em;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 0;
  border-width: 0 0 0 0.5em;
  border-style: solid;
  border-color: orange;
  margin: 0.2em 0 0.8em;
  padding: 0.2em 0 0.2em 0.5em;
  text-transform: uppercase;
}

.tplEditor h2 {
  font-size: 2em;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 0;
  margin: 0.7em 0;
}

.tplEditor h3 {
  font-size: 1.6em;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: 0;
  margin: 0.6em 0;
}

.tplEditor blockquote {
  font-size: 1em;
  line-height: 1.3;
  letter-spacing: 0;
  font-style: italic;
  border-width: 0 0 0 0.2em;
  border-style: solid;
  border-color: gray;
  margin: 0.6em 0;
  padding: 0.4em 0 0.4em 0.6em;
  opacity: 0.7;
}

.tplEditor ul,
.tplEditor ol {
  font-size: 1em;
  margin: 0.9em 0;
  padding: 0 0 0 2.2em;
}

.tplEditor li {
  font-size: 1em;
  margin: 0.3em 0;
  padding: 0;
}

.tplEditor p {
  font-size: 1em;
  line-height: 1.3;
  margin: 0.5em 0;
  letter-spacing: 0.02em;
}

.tplEditor a {
  color: #df8500;
  text-decoration: underline;
}

@import './colors.css';
@import './fonts.css';
@import './tplEditor.css';

body {
  margin: 0;
  padding: 0;
  background-color: var(--gray-1);
  font-family: CircularStd, Arial, sans-serif;
  font-weight: 300;
  color: var(--black);
}

a {
  text-decoration: none;
}

/* remove input padding for social select-input */
.react-select-social__value-container > input {
  height: 0;
}

/* default z-index is 2147480000, so it goes above everything */
#appzi-launch-button-d540e01f-2a69-45ae-a62f-007f9dd634ec {
  z-index: 1 !important;
}

/* show line number for prism editor */
.container_editor_area {
  overflow: auto;
  margin: 1.67em 0;
  padding-left: 3em;
}

.npm__react-simple-code-editor__textarea,
.container__editor > pre {
  padding: 10px 30px !important;
}
.container__editor {
  font-variant-ligatures: common-ligatures;
  counter-reset: line;
}
.container_editor_line_number:before {
  position: absolute;
  right: 100%;
  margin-right: -20px;
  text-align: right;
  opacity: 0.3;
  user-select: none;
  counter-increment: line;
  content: counter(line);
}

.button {
  cursor: pointer;
}

.icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

.menu > * {
  display: inline-block;
}

.menu > * + * {
  margin-left: 15px;
}

.toolbar {
  position: relative;
  padding: 12px 0 17px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
}

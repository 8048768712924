:root {
  --black: #1f2123;

  --gray-1: #f4f7fc;
  --gray-2: #949fa9;
  --gray-3: #575f67;
  --gray-4: #f6f8fb;

  --blue-1: #f2f9ff;
  --blue-2: #358bf1;
  --blue-3: #d7e8fc;
  --blue-4: #0052CC;  /* main color of current atlassian theme */

  --green-1: #ecfdeb;
  --green-2: #58be40;
  --green-3: #00ffa8;

  --orange-1: #fff3e8;
  --orange-2: #f7dec3;
  --orange-3: #f79c3b;

  --red-1: #fdefee;
  --red-2: #df4841;
}
